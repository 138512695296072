* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  width: 6px;
}

*::-webkit-scrollbar-thumb {
  background: #767676;
  border-radius: 6px;
}

*::-webkit-scrollbar-track-piece {
  background-color: #F0F2F5;
}

@media screen and (max-width: 1440px) {
  html {
    font-size: 14px;
  }
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
